import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Box from "@mui/material/Box";
import Seo from "components/common/seo";
import { TermsContainer } from "./styles";
import { ContentType } from "../privacy/types";
import { ContentSectionType } from "./types";
import { PrismicRichText } from "@prismicio/react";

interface TermsPageContainer {}

const TermsPage: React.FC<TermsPageContainer> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicTermsOfUse {
        nodes {
          data {
            company_name {
              richText
            }
            title {
              richText
            }
            last_updated(formatString: "MMMM DD, yyyy")
            welcome_message {
              richText
            }
            first_paragraph {
              richText
            }
            second_paragraph {
              richText
            }
            third_paragraph {
              richText
            }
            fourth_paragraph {
              richText
            }
            arbitration_notice {
              richText
            }
            body {
              ... on PrismicTermsOfUseDataBodyContentBlock {
                id
                primary {
                  content_title {
                    richText
                  }
                }
                items {
                  content_section_paragraph {
                    richText
                  }
                }
              }
              ... on PrismicTermsOfUseDataBodyScheduleA {
                id
                primary {
                  section_title {
                    richText
                  }
                  section_secondary_title {
                    richText
                  }
                  section_paragraph {
                    richText
                  }
                }
                items {
                  terms_paragraph {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data;
  return (
    <>
      <Seo />

      <TermsContainer className="terms-container">
        <Box className="terms-max-width">
          <PrismicRichText field={document.company_name.richText} />
          <PrismicRichText field={document.title.richText} />
          <PrismicRichText field={document.welcome_message.richText} />
          <p>Last updated: {document.last_updated}</p>
          <Box className="privacy-header-paragraphs">
            <PrismicRichText field={document.first_paragraph.richText} />
            <PrismicRichText field={document.second_paragraph.richText} />
            <PrismicRichText field={document.third_paragraph.richText} />
            <PrismicRichText field={document.fourth_paragraph.richText} />
            <PrismicRichText field={document.arbitration_notice.richText} />
          </Box>
          <Box className="main-content-container">
            {document.body.map((content: ContentType, index: number) => {
              if (index === document.body.length - 1) return;
              return (
                <Box key={index} className="content-container">
                  <PrismicRichText
                    field={content.primary.content_title.richText}
                  />

                  <Box className="content-items-container">
                    {content.items.map((contentItem, index: number) => {
                      return (
                        <Box key={index} className="single-item-container">
                          <PrismicRichText
                            field={
                              contentItem.content_section_paragraph.richText
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
            {document.body.map((content: ContentSectionType, index: number) => {
              if (index !== document.body.length - 1) return;
              return (
                <Box key={index} className="content-container">
                  <PrismicRichText
                    field={content.primary.section_title.richText}
                  />
                  <PrismicRichText
                    field={content.primary.section_secondary_title.richText}
                  />
                  <PrismicRichText
                    field={content.primary.section_paragraph.richText}
                  />
                  <Box className="content-items-container">
                    {content.items.map((contentItem, index: number) => {
                      return (
                        <Box key={index} className="single-item-container">
                          <PrismicRichText
                            field={contentItem.terms_paragraph.richText}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </TermsContainer>
    </>
  );
};

export default TermsPage;
