import React from "react";
import TermsPage from "../components/pages/terms";

interface TermsOfUseProps {}

const TermsOfUse: React.FC<TermsOfUseProps> = () => {
  return <TermsPage />;
};

export default TermsOfUse;
